import {
  Box,
  Button,
  CssBaseline,
  InputBase,
  Link,
  makeStyles,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import ReactInputMask from "react-input-mask";

const useStyles = makeStyles((theme) => ({
  timeDisplay: {
    fontSize: "15vw",
    textAlign: "center",
  },
  timeDisplayHidden: {
    visibility: "hidden",
  },
  button: {
    padding: "16px 32px",
    fontSize: "1.875rem",
    marginRight: theme.spacing(2),
    "&:last-child": {
      marginRight: 0,
    },
  },
  root: {
    background: "url(/assets/BACKGROUND.jpg)",
    backgroundSize: "cover",
  },
  logoItem: {
    width: "auto",
    height: "8vw",
  },
  logoLeft: {
    position: "fixed",
    top: theme.spacing(3),
    left: theme.spacing(5),
  },
  logoRight: {
    position: "fixed",
    top: theme.spacing(3),
    right: theme.spacing(5),
  },
  logoSponsor: {
    position: "fixed",
    bottom: theme.spacing(6),
    width: "calc(100% - (40px * 2))",
    height: "auto",
    [theme.breakpoints.up("lg")]: {
      width: "auto",
      height: "10.5vh",
    },
  },
}));

const MaskedInput = (props) => {
  const { inputRef, ...rest } = props;

  return (
    <ReactInputMask
      {...rest}
      ref={(ref) => inputRef(ref ? ref.inputElement : null)}
    />
  );
};

MaskedInput.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

function App() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    timerState: "idle",
    text: "00:00:00",
    originalText: "00:00:00",
    flashSequence: 0,
  });

  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (state.timerState === "running") {
        const seconds = moment.duration(state.text).asSeconds() - 1;
        const formattedText = moment.utc(seconds * 1000).format("HH:mm:ss");

        if (formattedText === "00:00:00") {
          setState((prevState) => ({
            ...prevState,
            timerState: "done",
            text: formattedText,
          }));
        } else {
          setState((prevState) => ({
            ...prevState,
            text: formattedText,
          }));
        }
      }
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [state.text, state.timerState]);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (state.timerState === "done") {
        if (state.flashSequence < 20) {
          setState((prevState) => ({
            ...prevState,
            flashSequence: state.flashSequence + 1,
          }));
        } else {
          setState((prevState) => ({
            ...prevState,
            timerState: "idle",
            flashSequence: 0,
          }));
        }
      }
    }, 250);

    return () => {
      clearTimeout(timer);
    };
  }, [state.timerState, state.flashSequence]);

  const handleStartPauseClick = () => {
    if (state.timerState === "idle") {
      setState({
        ...state,
        timerState: "running",
      });
    } else if (state.timerState === "running") {
      setState({
        ...state,
        timerState: "paused",
      });
    } else if (state.timerState === "paused") {
      setState({
        ...state,
        timerState: "running",
      });
    }
  };

  const handleStopClick = () => {
    setState({
      ...state,
      text: state.originalText,
      timerState: "idle",
    });
  };

  const handleChange = (event) => {
    setState({
      ...state,
      text: event.target.value,
      originalText: event.target.value,
    });
  };

  return (
    <div className={classes.root}>
      <CssBaseline />

      <img
        src="/assets/AWARDS.png"
        alt="CC-APAC Awards 2021"
        className={clsx(classes.logoItem, classes.logoLeft)}
      />

      <img
        src="/assets/CC-APAC.png"
        alt="Contact Center Association of Asia Pacific"
        className={clsx(classes.logoItem, classes.logoRight)}
      />

      <Box
        display="flex"
        flexDirection="column"
        height="100vh"
        alignItems="center"
        justifyContent="center"
      >
        <InputBase
          inputProps={{
            className: clsx(
              classes.timeDisplay,
              !(state.flashSequence % 2 === 0) && classes.timeDisplayHidden
            ),
            mask: "99:99:99",
            maskPlaceholder: "0",
            name: "text",
            value: state.text,
            onChange: handleChange,
            readOnly: ["running", "paused", "done"].includes(state.timerState),
          }}
          inputComponent={MaskedInput}
        />

        <Box mt={1}>
          <Button
            className={classes.button}
            variant="contained"
            onClick={handleStartPauseClick}
            disabled={state.text === "00:00:00"}
          >
            {state.timerState === "idle"
              ? "Start"
              : state.timerState === "paused"
              ? "Resume"
              : "Pause"}
          </Button>

          <Button
            className={classes.button}
            variant="contained"
            onClick={handleStopClick}
            disabled={["idle", "done"].includes(state.timerState)}
          >
            Stop
          </Button>
        </Box>

        <Box my={2}>
          <Typography variant="body2" color="textSecondary" align="center">
            {"Copyright © "}
            <Link color="inherit" href="https://cc-apac.org/">
              Contact Center Association of Asia Pacific
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
          </Typography>
        </Box>

        <img
          src="/assets/LOGO.png"
          alt="Sponsors"
          className={classes.logoSponsor}
        />
      </Box>
    </div>
  );
}

export default App;
